module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-LQN806EWJK"],"pluginConfig":{"head":false,"respectDNT":true,"delayOnRouteUpdate":1000,"exclude":[],"origin":"https://www.googletagmanager.com"},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"yourssu","short_name":"yourssu","start_url":"/","icon":"src/assets/logo/logo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"441379b834e973c962f6d88c1fdba0f8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xs":"(max-width: 390px)","query550":"(max-width: 550px)","query669":"(max-width: 669px)","sm":"(max-width: 720px)","md":"(max-width: 1080px)","lg":"(max-width: 1440px)","xl":"(max-width: 1920px)"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
